import React, {useState} from 'react'
import { styled } from "@mui/material/styles";
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { StatusOkSvg } from '../../../components/svg/status-ok-svg';
import { StatusErrorSvg } from '../../../components/svg/status-error-svg';
import { StatusWaitingSvg } from '../../../components/svg/status-waiting-svg';
import {Input} from '../../../components/input';
import { Button } from '../../../components/button';
import CloseIcon from '@mui/icons-material/Close';



const MappingPageOrderItem = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '12px',
    alignItems: 'flex-start',
    position: 'relative',
});

const WrapDeleteEl = styled('div')({
    position: 'absolute',
    right: '-32px',
    top: '7px',

    '@media(max-width: 420px)' : {
        top: '28px',
    }
});

type OrderItemProps = {
    status: string;
}

const MappingPageOrderItemNumber = styled('div')<OrderItemProps>(({ status, theme }) => ({
    fontSize: '14px',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '24px',
    height: '24px',
    background: theme.palette.mode === 'light' ?
    status === 'active' || status === 'waiting' ? '#92CD91' : status === 'inactive' ? '#E3E8EC' : '#979799'
    : status === 'active' || status === 'waiting' ? '#38AA72' : status === 'inactive' ? theme.palette.background.default : '#65646B',
    borderRadius: '12px',
    marginRight: '16px',
    marginTop: '9px',
    aspectRatio: '1',
}));

const MappingPageOrderItemInfo = styled('div')<OrderItemProps>(({ status, theme }) => ({
    background: theme.palette.mode === 'light' ?
    status === 'active' || status === 'waiting' ? '#F1F8ED' : status === 'inactive' ? '#F9FBFD' : '#F1F3F5'
    : status === 'inactive' ? theme.palette.background.paper : theme.palette.background.default,
    border: theme.palette.mode === 'light' ? 'none' : `1px solid #65646B`,
    borderRadius: '4px',
    minHeight: '32px',
    height: 'auto',
    width: 'calc(100% - 24px - 16px)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 8px',
    flexWrap: 'wrap',

    '@media(max-width: 420px)' : {
        padding: '8px',
        position: 'relative',
    }
}));

const MappingPageOrderItemInfoNameText = styled('div')({
    fontWeight: '500',
    fontSize: '11px'
});

const MappingPageOrderItemInfoMethodText = styled('div')({
    fontWeight: '500',
    fontSize: '11px'
});

const MappingPageOrderItemInfoTextContainer = styled('div')<OrderItemProps>(({ status, theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: theme.palette.mode === 'light' ?
    status === 'active' || status === 'waiting' ? '#38AA72' : status === 'inactive' ? '#979799' : '#404653'
    : status === 'inactive' ? theme.palette.text.disabled : theme.palette.text.primary,

    '@media(max-width: 420px)' : {
        flexWrap: 'wrap',
        paddingRight: '20px',
    }
}));

const MappingPageOrderItemInfoCircle = styled('div')<OrderItemProps>(({ status, theme }) => ({
    width: '4px',
    height: '4px',
    borderRadius: '2px',
    background: theme.palette.mode === 'light' ?
    status === 'active' || status === 'waiting' ? '#38AA72' : status === 'inactive' ? '#979799' : '#404653'
    : status === 'inactive' ? theme.palette.text.disabled : theme.palette.text.primary,
    marginRight: '8px',
    marginLeft: '8px',
}));

const MappingPageOrderItemInfoStatus = styled('span')<OrderItemProps>(({ status }) => ({
    border: `1px solid ${status === 'active' ? '#6FC52D' : status === 'waiting' ? '#C0BDC9' : '#EDA32A'}`,
    color: status === 'active' ? '#6FC52D' : status === 'waiting' ? '#65626B' : '#EDA32A',
    borderRadius: '8px',
    fontSize: '12px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 6px',
    marginLeft: 'auto',
    position: 'relative',
    // left: '-12px',

    '@media(max-width: 768px)' : {
        padding: '0px',
    },

    '@media(max-width: 420px)' : {
        left: 'auto',
        right: '2px',
        position: 'absolute',
    }

}));

const ModeEditOutlineOutlinedIconStyled = styled(ModeEditOutlineOutlinedIcon)(({ theme }) => ({
    color: theme.palette.text.primary, 
    transform: 'scale(.8)',
    cursor: 'pointer',
    transition: 'color 200ms',
    '&:hover': {
        color: '#38AA72',
    }
}));

const MappingPageOrderItemInfoStatusSvg = styled('div')({
    marginRight: '6px',
    display: 'flex',
    alignItems: 'center',

    '@media(max-width: 768px)' : {
        marginRight: '0px',
    }
});

const WrapInput = styled('div')({
    width: '100%',
    display: 'flex',
    gap: '8px',
    marginTop: '4px',
});

const CloseIconStyled = styled(CloseIcon)({
    color: '#D63639',
    cursor: 'pointer',
});

const WrapWditModeIcon = styled('div')({
    '@media(max-width: 420px)' : {
        position: 'absolute',
        right: '-32px',
        top: '-3px',
    }
});



// const timeRegExp = /^(?:(\d+d\s?)?(\d+h\s?)?(\d+m\s?)?(\d+s?)?)$/i;
const numberRegExp = /^[0-9]*$/;

export const OrderItemSelectElement = ({
    provided, 
    itemEl, 
    index, 
    removeMethod, 
    onSave,
    accessedUserChangeData,
}) => {

    const [open, setOpen] = useState(false);
    const handleOpenRule = () => {
        setOpen(prev => !prev)
    }

    const handleDeleteMethod = (index, item) => {
        removeMethod(index, item)
    }

    const [sum, setSum] = useState<any>(Number(String(itemEl?.rules?.sum_limit).replace(/\s/g, '')).toLocaleString().replace(/,/g, ' '));
    const handleChangeSum = (event) => {
        const value = event.target.value;
        const isValidFormat = numberRegExp.test(value.replace(/\s/g, ''));
        if (isValidFormat) {
            setSum(Number(event.target.value.replace(/\s/g, '')).toLocaleString().replace(/,/g, ' '));
        }
    };

    const handleSaveSum = () => {
        onSave(itemEl, index, sum)
        handleOpenRule()
    }

    return (
        <MappingPageOrderItem 
            key={index}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            <MappingPageOrderItemNumber status={itemEl?.status}>{index + 1}</MappingPageOrderItemNumber>
            <MappingPageOrderItemInfo status={itemEl?.status}>
                <MappingPageOrderItemInfoTextContainer status={itemEl?.status}>
                    <MappingPageOrderItemInfoNameText>{itemEl?.psname?.toUpperCase()}</MappingPageOrderItemInfoNameText>
                    { itemEl?.type === 'method' && 
                        <>
                            <MappingPageOrderItemInfoCircle status={itemEl?.status}/>
                            <MappingPageOrderItemInfoMethodText>{itemEl?.accname?.toUpperCase()}</MappingPageOrderItemInfoMethodText>
                        </>
                    }
                    <MappingPageOrderItemInfoCircle status={itemEl?.status}/>
                    <MappingPageOrderItemInfoMethodText>{itemEl?.name?.toUpperCase()}</MappingPageOrderItemInfoMethodText>
                </MappingPageOrderItemInfoTextContainer>
                {itemEl?.status && (
                    <MappingPageOrderItemInfoStatus 
                        status={itemEl?.status}
                        sx={{
                            marginRight: '8px',
                            top: open && window.screen.width <= 420 ? '8px' : 'auto',
                            left: window.screen.width <= 420 ? 'auto' : '-12px',
                            right: window.screen.width <= 420 ? '8px' : 'auto',
                        }}
                    >
                        <MappingPageOrderItemInfoStatusSvg>
                            {itemEl?.status === 'active' ? <StatusOkSvg size="12px" /> : itemEl?.status === 'inactive' ? <StatusErrorSvg size="12px" fill="#FA8C16" /> : <StatusWaitingSvg size="12px" />}
                        </MappingPageOrderItemInfoStatusSvg>
                        {window.screen.width > 767 && itemEl?.status}
                    </MappingPageOrderItemInfoStatus>
                )}
                <WrapWditModeIcon 
                    onClick={handleOpenRule}
                >
                    {open 
                        ? <CloseIconStyled/>                    
                        : <ModeEditOutlineOutlinedIconStyled 
                            style={{color: itemEl?.isChanged && '#38AA72' }} 
                        />
                    }
                </WrapWditModeIcon>

                {open && 
                    <WrapInput>
                        <Input
                            placeholder="Set sum limit"
                            value={sum}
                            label="Sum"
                            onChange={handleChangeSum}
                        />
                        <Button
                            onClick={handleSaveSum}
                            variant='outlined'
                            disabled={!accessedUserChangeData}
                            sx={{
                                marginTop: '8px',
                                height: '39px !important',
                            }}
                        >
                            Save
                        </Button>
                    </WrapInput>
                }
            </MappingPageOrderItemInfo>
            
            {accessedUserChangeData &&
                <WrapDeleteEl>
                    <DeleteOutlined
                        sx={{  
                            color: '#ad3b3b',
                            cursor: 'pointer',
                            marginLeft: '8px',
                        }}
                        onClick={() => handleDeleteMethod(index, itemEl)}
                    />
                </WrapDeleteEl>
            }
        </MappingPageOrderItem>
    )
}