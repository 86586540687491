import React, {Component} from "react";
import { styled } from "@mui/material/styles";
import { RootState } from '../../store/configure-store';
import { compose } from "redux";
import { connect } from 'react-redux';
import { AccessDeniedAlert } from "../../components/access-denied";
import { Loader } from '../../components/loaders/loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import TablePagination from '@mui/material/TablePagination';
import {
    apiInfoFetchList,
    apiInfoFetchCurrencyList,
    apiInfoFetchPMMethodsList,
} from '../../actions/apiInfo';
import {getPaymentSystemsListRequest} from '../../actions/paymentSystems';
import {Button} from '../../components/button';
import DownloadIcon from '@mui/icons-material/Download';
import {CopyClipboardButton} from '../../components/clipboard-copy/button-copy';
import UpdateIcon from '@mui/icons-material/Update';
import {getApiCatalogFetchList} from '../../actions/apiCatalog'
import {isJson} from '../../utils';
import {baseURL} from '../../services/api/_axiosBaseUrl';


const WrapperPage = styled('div')(({theme}) => ({}));

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px 40px 0 40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const DepositMainHeaderText = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const DepositMainHeader = styled('div')({
    display: 'flex',
    marginBottom: '32px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
        marginBottom: '24px',
    }
});

const DepositMainSecondRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '32px',

    '@media(max-width: 1260px)' : {
        flexWrap: 'wrap',
        marginBottom: '24px',

    }
});

const DepositMainSecondRowRightBlock = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
});

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};
    position: sticky;
    z-index: 10;
    top: 0;
    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
    }   
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    .MuiPaper-root{
        border-radius: 0;
        box-sizing: border-box;
        box-shadow: none;
    }
    & .overlay-scroll-table{
        max-height: calc(100vh - 260px - 56px);
        @media(max-width: 767px){
            max-height: calc(100vh - 250px - 56px);
        }; 
    }
`));

const WrapActions = styled('div')(({ theme }) => (`
    display: flex;
    justify-content: center;
`));

// type data = {
//     'paymentSystem.id[]': number,
//     'page': number,
//     'way[]': string,
//     'currency[]': Array<string>,
//     'paymentMethod[]': Array<string>,
//     'order[dateLastTransaction]': string,
//     'itemsPerPage': number,
// }

type PropsType = {
    isLoading?: boolean, 
    APILIst?: Array<any>,
    listCurrency?: Array<string>,
    litPSMethod?: Array<string>,
    totalItems?: number,
    totalItemsFiles?: number,
    routsUser?: Array<any>,
    userRoles?: Array<any>,
    isSuperadmin?: number,
    paymentSystemsList?: Array<any>,
    apiCatalogList?: Array<any>,
    apiInfoFetchList?: (data: any) => void,
    apiInfoFetchCurrencyList?: () => void,
    apiInfoFetchPMMethodsList?: () => void,
    getPaymentSystemsListRequest?: () => void,
    getApiCatalogFetchList?: (data: any) => void,
}

type StateProps = {
    page: number,
    rowsPerPage: number,
    anchorElWay: null | any,
    way: Array<string>,
    anchorElCurrency: null | any,
    currency: Array<string>,
    anchorElPaymentMethod: null | any,
    paymentMethod: Array<string>,
    anchorElPaymentSystems: null | any,
    paymentSystems: Array<string>,
    oeder: string,
    itemsPerPage: number,
}


export class ApiInfoCatalog extends Component<PropsType, StateProps> {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 20,
            anchorElWay: null,
            way: [],
            anchorElCurrency: null,
            currency: [],
            anchorElPaymentMethod: null,
            paymentMethod: [],
            anchorElPaymentSystems: null,
            paymentSystems: [],
            oeder: 'desc',
            itemsPerPage: 50,
        }
    };

    handleChangePage = (event: React.MouseEvent<HTMLElement>, newPage: number) => {
        this.setState({page: newPage}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({page: 0, itemsPerPage: parseInt(event.target.value)}, () => {this.updateData()})
    };

    handleSearchSubmit = (prop) => (value) => {
        //@ts-ignore
        this.setState({[prop]: value, page: 0}, () => {this.updateData()});
    };
    handleCancelSearch = (prop) => () => {
        //@ts-ignore
        this.setState({[prop]: '', page: 0}, () => {this.updateData()});
    };

    handleWayMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElWay: e.currentTarget});
    }
    handleWayMenuClose = () => {
        this.setState({anchorElWay: null});
    }
    onChangeWayFilter = (data) => {
        this.setState({way: data}, () => {this.updateData()});
        this.handleWayMenuClose();
    }

    handleCurrencyMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElCurrency: e.currentTarget});
    }
    handleCurrencyMenuClose = () => {
        this.setState({anchorElCurrency: null});
    }
    onChangeCurrencyFilter = (data) => {
        this.setState({currency: data}, () => {this.updateData()});
        this.handleCurrencyMenuClose();
    }

    handlePaymentMethodMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElPaymentMethod: e.currentTarget});
    }
    handlePaymentMethodMenuClose = () => {
        this.setState({anchorElPaymentMethod: null});
    }
    onChangePaymentMethodFilter = (data) => {
        this.setState({paymentMethod: data}, () => {this.updateData()});
        this.handlePaymentMethodMenuClose();
    }

    handlePaymentSystemsMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElPaymentSystems: e.currentTarget});
    }
    handlePaymentSystemsMenuClose = () => {
        this.setState({anchorElPaymentSystems: null});
    }
    onChangePaymentSystemsFilter = (data) => {
        this.setState({paymentSystems: data}, () => {this.updateData()});
        this.handlePaymentSystemsMenuClose();
    }

    handleClickSortTopUpdate = () => {
        this.setState({oeder: 'asc'}, () => this.updateData())
    };

    handleClickSortBottomUpdate = () => {
      this.setState({oeder: 'desc'}, () => this.updateData())
    };

    handleClearFilters = () => {
        this.setState({
                page: 0,
                anchorElWay: null,
                way: [],
                anchorElCurrency: null,
                currency: [],
                anchorElPaymentMethod: null,
                paymentMethod: [],
                anchorElPaymentSystems: null,
                paymentSystems: [],
                oeder: 'desc',
            }, 
            () => {this.updateData()}
        )
    }
    
    componentDidMount() {
        const {
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props;

        if(
            routsUser.some(rout => rout.child.includes('/api-info/catalog')) || 
            userRoles.some(role => role.name === 'Admin') || 
            !!isSuperadmin
        ){
            this.updateData();
        }
    }

    updateData = () => {
        const {
            paymentSystems,
            page,
            way,
            currency,
            paymentMethod,
            oeder,
            itemsPerPage,
        } = this.state;

        const {
            getApiCatalogFetchList,
        } = this.props;

        const data = {
            'paymentSystem.id[]': paymentSystems,
            'page': page + 1,
            'way[]': way,
            'currency[]': currency,
            'paymentMethod[]': paymentMethod,
            'order[dateLastTransaction]': oeder,
            'itemsPerPage': itemsPerPage,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        getApiCatalogFetchList(data);
    }

    handleUpdateData = () => {
        this.updateData();
    }

    isClearedFilters = () => {
        const {                
            page,
            way,
            currency,
            paymentMethod,
            paymentSystems,
        } = this.state
        return !(page > 0 || way.length || currency.length || paymentMethod.length || paymentSystems.length)
    }

    render() {
        const {
            isLoading,
            userRoles, 
            routsUser,
            isSuperadmin,
            totalItemsFiles,
            apiCatalogList
        } = this.props

        const {
            page,
            // rowsPerPage,
            itemsPerPage,
        } = this.state;

        const accessedRole = routsUser.some(rout => rout.child.includes('/api-info/catalog')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        return (
            <WrapperPage>
                <PageContainer>

                    {!accessedRole && <AccessDeniedAlert/>}

                    {accessedRole &&
                        <>
                            <DepositMainHeader>
                                <DepositMainHeaderText>Generated files</DepositMainHeaderText>
                            </DepositMainHeader>
                            <DepositMainSecondRow>
                                <DepositMainSecondRowRightBlock>
                                    <Button
                                        variant="contained"
                                        onClick={this.handleUpdateData}
                                    >   
                                        Update data
                                        <UpdateIcon sx={{marginLeft: '8px'}}/>
                                    </Button>
                                </DepositMainSecondRowRightBlock>
                            </DepositMainSecondRow>
                            <StyledPaperTable>                               
                                <StyledTableContainer>

                                    <OverlayScrollbarsComponent
                                        className='overlay-scroll-table'
                                        options={{
                                            scrollbars: { visibility: 'visible' } 
                                        }}
                                    >

                                        <Table>

                                            <StyledTableHead>
                                                <TableRow>

                                                    <TableCell width={250}>
                                                        Date
                                                    </TableCell>

                                                    <TableCell width={250}>
                                                        Id
                                                    </TableCell>

                                                    {!!isSuperadmin &&
                                                        <TableCell width={250}>
                                                            User
                                                        </TableCell>
                                                    }

                                                    <TableCell width={400}>
                                                        Description
                                                    </TableCell>

                                                    <TableCell>
                                                        Action
                                                    </TableCell>
                
                                                </TableRow>
                                            </StyledTableHead>

                                            <TableBody>

                                                {
                                                    (apiCatalogList || []).map((item, i) => {
                                                        return  <TableRow key={i}>
                                                            <TableCell>
                                                                {item.createdAt}
                                                            </TableCell>

                                                            <TableCell>
                                                                {item.id}
                                                            </TableCell>

                                                            {!!isSuperadmin &&
                                                                <TableCell>
                                                                    {item?.user?.username}
                                                                </TableCell>
                                                            }

                                                            <TableCell>
                                                                {
                                                                    isJson(item.params) 
                                                                        ? Object.keys(JSON.parse(item.params)).map((key, y) => {
                                                                            return <div key={y}>{key}: {
                                                                                JSON.parse(item.params)[key].map((itemKey, iy) => {
                                                                                    return <span key={iy}>{`${itemKey} `}</span>
                                                                                })
                                                                            }</div>
                                                                        })
                                                                        : ''
                                                                }
                                                            </TableCell>

                                                            <TableCell width={250}>
                                                                <WrapActions>
                                                                    { item.hasOwnProperty('url') && 
                                                                        <>
                                                                            <a 
                                                                                href={`${baseURL}${item.url}`} 
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                            >
                                                                                <Button
                                                                                    variant="outlined"
                                                                                >
                                                                                    <DownloadIcon sx={{marginRight: '0px'}}/>
                                                                                </Button>
                                                                            </a>
                                                                            <CopyClipboardButton 
                                                                                sx={{marginLeft: '16px'}} 
                                                                                data={`${baseURL}${item.url}`}
                                                                            />
                                                                        </>
                                                                    }
                                                                </WrapActions>
                                                            </TableCell>
                                                        </TableRow>
                                                    })
                                                }
                                            </TableBody>
                                        </Table>

                                    </OverlayScrollbarsComponent>

                                </StyledTableContainer>     

                                <TablePagination
                                    rowsPerPageOptions={[itemsPerPage]}
                                    // rowsPerPageOptions={[20]}
                                    component="div"
                                    count={totalItemsFiles}
                                    rowsPerPage={itemsPerPage}
                                    page={page}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />
                            </StyledPaperTable>
                        </>
                    }

                    { isLoading && <Loader/> }
    
                </PageContainer>
            </WrapperPage>
        )
    }
}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.apiCatalog.isLoading, 
        apiCatalogList: state.apiCatalog.apiCatalogList['hydra:member'],
        totalItemsFiles: state.apiCatalog.apiCatalogList['hydra:totalItems'],
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        apiInfoFetchList,
        apiInfoFetchCurrencyList,
        apiInfoFetchPMMethodsList,
        getPaymentSystemsListRequest,
        getApiCatalogFetchList,
    }),
)(ApiInfoCatalog)