import {ActionCatalogTypes} from '../reducers/apiCatalog';
import {ActionTypes} from '../reducers/apiInfo';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionCatalogTypes.FETCH_API_CATALOG_START,
});

export const requestSuccess = list => {
	return {
		type: ActionCatalogTypes.FETCH_API_CATALOG_SUCCESS,
		payload: list
	};
};

export const requestFailure = error => {
	return {
		type: ActionCatalogTypes.FETCH_API_CATALOG_FAILURE,
		payload: error
	}
};

export const requestGenerateSuccess = data => {
	return {
		type: ActionTypes.FETCH_API_INFO_GENERATED_SUCCESS,
		payload: data
	}
};

export const requestGenerateError = error => {
	return {
		type: ActionTypes.FETCH_API_INFO_GENERATED_ERROR,
		payload: error,
	}
};

export const getApiCatalogFetchList = data => dispatch => {
	dispatch(requestStart());
	return API.apiCatalog
		.apiCatalogList(data)
		.then(response => {
			dispatch(requestSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const catalogGenerateFile = data => dispatch => {
	dispatch(requestStart());
	return API.apiCatalog
		.apiCatalogCreate(data)
		.then(response => {
			dispatch(requestGenerateSuccess(response.data));
		})
		.catch(error => dispatch(requestGenerateError(error)));
};
