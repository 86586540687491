import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/payment_system_catalog_export';

const ApiCatalog = {
	apiCatalogList: (params = {}) => {
		const query = Object.keys(params).length ? `?${queryString.stringify(params)}` : '';
		return baseApi.get(`${path}${query}`)
	},
	apiCatalogCreate: (data) => {
		return baseApi.post(`${path}`, data)
	},
}

export default ApiCatalog;