export namespace ActionTypes {
    export const FETCH_API_INFO_START = 'FETCH_API_INFO_START';
    export const FETCH_API_INFO_SUCCESS = 'FETCH_API_INFO_SUCCESS';
    export const FETCH_API_INFO_FAILURE = 'FETCH_API_INFO_FAILURE';
    export const FETCH_API_INFO_CURRENCY_SUCCESS = 'FETCH_API_INFO_CURRENCY_SUCCESS';
    export const FETCH_API_INFO_PSMETHOD_SUCCESS = 'FETCH_API_INFO_PSMETHOD_SUCCESS';

    export const FETCH_API_INFO_GENERATED_SUCCESS = 'FETCH_API_INFO_GENERATED_SUCCESS';
    export const FETCH_API_INFO_GENERATED_ERROR = 'FETCH_API_INFO_GENERATED_ERROR';
};

export type APIInfoItem = any;

type APIInfoList = {
	"hydra:totalItems": number,
	"hydra:member": Array<APIInfoItem>,
};

interface APIInfo {
	isLoading: boolean,
	apiInfoList: APIInfoList,
	listCurrency: Array<any>,
	litPSMethod: Array<any>,
	isErrorGenerate: boolean,
	generatedFile: any,
	errorMessageGenetate: any,
}

export const initialState: APIInfo = {
	isLoading: false,
	apiInfoList: {
		"hydra:totalItems": 0,
		"hydra:member": [],
	},
	listCurrency: [],
	litPSMethod: [],
	isErrorGenerate: false,
	generatedFile: {
		id: 0,
	},
	errorMessageGenetate: "",
}

export const apiInfo = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_API_INFO_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_API_INFO_SUCCESS:
			return {
				...state,
				apiInfoList: payload,
				isLoading: false,
			};	
		case ActionTypes.FETCH_API_INFO_CURRENCY_SUCCESS:
			return {
				...state,
				listCurrency: payload,
				isLoading: false,
			};	
		case ActionTypes.FETCH_API_INFO_PSMETHOD_SUCCESS:
			return {
				...state,
				litPSMethod: payload,
				isLoading: false,
			};		
		case ActionTypes.FETCH_API_INFO_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		case ActionTypes.FETCH_API_INFO_GENERATED_SUCCESS:
			return {
				...state,
				isErrorGenerate: false,
				errorMessageGenetate: '',
				generatedFile: payload
			};	
		case ActionTypes.FETCH_API_INFO_GENERATED_ERROR:
			return {
				...state,
				isErrorGenerate: true,
				errorMessageGenetate: payload
			};	
		default:
			return state;
	}
};
