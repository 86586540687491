import React from "react";
import { styled } from "@mui/material/styles";
import {Button} from '../../components/button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {SerachTableHeader} from '../../components/search-table-header';
import { Loader } from '../../components/loaders/loader';
// import Menu from '@mui/material/Menu';
// import { TableFilter } from "../../components/table-filter";
// import {FilterListSvg} from "../../components/svg/filter-list-svg";
// import Structure from '../../components/structure';
import { PlusSvg } from '../../components/svg/plus-svg';
// import { Status } from '../../components/status';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import {CreateUserForm} from './create-role-form'
import Dialog from '@mui/material/Dialog';
import {
    usersRolesRequest,
    userRoleCreate,
} from "../../actions/usersRoles";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import { AccessDeniedAlert } from "../../components/access-denied";
import { Link } from 'react-router-dom';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";


const WrapperPage = styled('div')(({theme}) => ({}));

const PageContainer = styled('div')(({theme}) => ({
    position: 'relative',
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const UsersPageHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '32px',

    '@media(max-width: 991px)' : {
        flexWrap: 'wrap'
    }
});

const UsersPageHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const UsersPageHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',

    '@media(max-width:991px)': {
        width: '100%',
        marginBottom: '16px',
        justifyContent: 'space-between'
    }
});

const UsersPageHeaderRight = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
});

const ActionsCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.mode === 'dark' ? '#272930' : 'white',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const ActionsHeaderCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.background.default,
    minWidth: '114px',
    boxSizing: 'border-box',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const StickyBorder = styled('div')(({ theme }) => (`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-left: 1px solid ${
        theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : 'rgba(224, 224, 224, 1)'
    };
`));

const ActionsContent = styled('div')({});

const WithSortedSerachTableHeader = styled(SerachTableHeader)(({ theme }) => (`
    position: relative;
    padding-right: 30px;
    min-width: 120px;
    width: 25%;
    box-sizing: border-box;
`));

const ActionIcon = styled('div')(({ theme }) => (`
    cursor: pointer;
    &:hover{
      svg{
        fill: #38AA72 !important;
      }
    }    
`));

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        };
        &:last-of-type{
            border-left: none;
        };
    }   
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

const StyledTableRow = styled(TableRow)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
`));

const RoleContent = styled(Link)(({theme}) => ({
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.text.primary,
}));

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        height: 100vh;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const StyledDialogEdit = styled(Dialog)(({ theme }) => (`
    .MuiPaper-root{
        margin: 15px;
        margin-top:0;
        margin-bottom:0;
        max-height: 100%;
        border-radius: 0;
    }
`));

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    .MuiPaper-root{
        border-radius: 0;
        box-sizing: border-box;
        box-shadow: none;
    }
    & .overlay-scroll-table{
        max-height: calc(100vh - 195px - 56px);
        @media(max-width: 767px){
            max-height: calc(100vh - 250px - 56px);
        }; 
    }
`));


export class Roles extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            isOpenCreateForm: false,
            page: 0,
            rowsPerPage: 20,
            description: '',
            code: '',
        }
    };
    
    handleClearFilters = () => {
        this.setState({page: 0}, () => this.updateData());
    }

    handleRoleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElRole: event.currentTarget});
    };
    handleRoleMenuClose = () => {
        this.setState({anchorElRole: null});
    };

    handleStatusMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElStatus: event.currentTarget})
    };
    handleStatusMenuClose = () => {
        this.setState({anchorElStatus: null});
    };

    handleSuperadminMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElSuperadmin: event.currentTarget})
    };
    handleSuperadminMenuClose = () => {
        this.setState({anchorElSuperadmin: null});
    };

    handleConfirmedMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElConfirmed: event.currentTarget})
    };
    handleConfirmedMenuClose = () => {
        this.setState({anchorElConfirmed: null});
    };

    handleChangePasswordModalOpen = () => {
        this.setState({isOpenChangePassword: true})
    };
    handleCheckPasswordModalClose = () => {
        this.setState({isOpenChangePassword: false})
    };

    handleCreateFormOpen = () => this.setState({isOpenCreateForm: true});
    handleCreateFormClose = () => this.setState({isOpenCreateForm: false});

    handleChangePage = (event: React.MouseEvent<HTMLElement>, newPage: number) => {
        this.setState({page: newPage}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0})
    };

    handleSearchSubmit = (prop) => (value) => {
        this.setState({[prop]: value, page: 0}, () => {this.updateData()});
    };
    handleCancelSearch = (prop) => () => {
        this.setState({[prop]: '', page: 0}, () => {this.updateData()});
    };

    handleClickOpenWindowDelete = () => {
        this.setState({openDeleteWindow: true})
    }
    handleClickCloseWindowDelete = () => {
        this.setState({openDeleteWindow: false})
    }
    handleOpenDeleteDialog = (id) => {
        this.setState({deleteId: id}, () => this.handleClickOpenWindowDelete())
    }
    onChangeFilter = (data) => {
        this.setState({statuses: data}, () => {this.updateData()});
    };

    handleOpenInfoItem = (id) => {
        const {setView} = this.props
        setView((prev) => ({id: id, point: 'view'}))
    }

    handleCreateRole = (data) => {
        const {userRoleCreate} = this.props;
        userRoleCreate(data)
    }

    componentDidMount() {
        const {
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props;

        if(routsUser.some(rout => rout.child.includes('/user-management/role/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            this.updateData();
        }
    }

    updateData = () => {

        const {
            page,
            code,
            description,
        } = this.state;

        const {
            usersRolesRequest,
        } = this.props;

        const data = {
            'page': page + 1,
            description,
            name: code,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        usersRolesRequest(data);
    }

    render() {

        const {
            isLoading,
            rolesList,
            routsUser, 
            userRoles, 
            isSuperadmin,
            totalCount,
        } = this.props;

        const {
            page,
            rowsPerPage,
            isOpenCreateForm,
            description,
            code,
            // ....,
        } = this.state;

        let iterationCount = 0;

        const accessedRole = routsUser.some(rout => rout.child.includes('/user-management/role/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        return (
            <WrapperPage>
                <PageContainer>

                    {!accessedRole && <AccessDeniedAlert/>}

                    {accessedRole &&
                        <>
                            <UsersPageHeader>
                                <UsersPageHeaderLeft>
                                    <UsersPageHeaderText>Roles</UsersPageHeaderText>
                                </UsersPageHeaderLeft>
                                <UsersPageHeaderRight>
                                    <Button 
                                        startIcon={<PlusSvg width="22px" />} 
                                        onClick={this.handleCreateFormOpen}
                                    >
                                        Create
                                    </Button>
                                </UsersPageHeaderRight>
                            </UsersPageHeader>
            
                            <StyledPaperTable>                               
                                <StyledTableContainer>
                                    <OverlayScrollbarsComponent
                                            className='overlay-scroll-table'
                                            options={{
                                                scrollbars: { visibility: 'visible' } 
                                            }}
                                    >
                                        <Table>
                                            <StyledTableHead>
                                                <TableRow>
                                                
                                                    <TableCell>№</TableCell>
                
                                                    <WithSortedSerachTableHeader 
                                                        label='Description' 
                                                        handleSearchSubmit={this.handleSearchSubmit('description')}
                                                        handleCancelSearch={this.handleCancelSearch('description')}
                                                        isActiveFilter={Boolean(description)}
                                                    />
                
                                                    <WithSortedSerachTableHeader 
                                                        label='Code' 
                                                        handleSearchSubmit={this.handleSearchSubmit('code')}
                                                        handleCancelSearch={this.handleCancelSearch('code')}
                                                        isActiveFilter={Boolean(code)}
                                                    />
                
                                                    <ActionsHeaderCell>
                                                        <StickyBorder>Action</StickyBorder>
                                                    </ActionsHeaderCell>
                
                                                </TableRow>
                                            </StyledTableHead>
                                            <TableBody>
                                                {(rolesList || []).map(row => {
                                                    return (
                                                        <StyledTableRow key={Math.random()}>
                                                            <TableCell>
                                                                {++iterationCount}
                                                            </TableCell>
                                                            <TableCell>
                                                                <RoleContent to={`/users/role/${row.name}`}>
                                                                    {row.description}
                                                                </RoleContent>
                                                            </TableCell>
                                                            <TableCell> 
                                                                {row.name}
                                                            </TableCell>
                                                            <ActionsCell>
                                                                <StickyBorder>
                                                                    <ActionsContent>   
                                                                        <Tooltip title='Details' placement="top">                                                  
                                                                            <ActionIcon>
                                                                                <RoleContent to={`/users/role/${row.name}`}>
                                                                                    <RemoveRedEyeIcon />
                                                                                </RoleContent>
                                                                            </ActionIcon>
                                                                        </Tooltip>    
                                                                    </ActionsContent>
                                                                </StickyBorder>
                                                            </ActionsCell>
                                                        </StyledTableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </OverlayScrollbarsComponent>
                                </StyledTableContainer>    
                                                           
                                <TablePagination
                                    rowsPerPageOptions={[20]}
                                    component="div"
                                    count={totalCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />
                            </StyledPaperTable>

                            <StyledDialogEdit
                                open={isOpenCreateForm}
                                onClose={this.handleCreateFormClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <StyledBoxPopup>
                                    <CreateUserForm 
                                        onClose={this.handleCreateFormClose}
                                        onCreate={this.handleCreateRole}
                                    />
                                </StyledBoxPopup>
                            </StyledDialogEdit>
                        </>
                    }
    
                    { isLoading && <Loader/> }
    
                </PageContainer>
            </WrapperPage>
        )
    }

}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.roles.isLoading, 
        rolesList: state.roles.roles['hydra:member'],
        totalCount: state.roles.roles['hydra:totalItems'],
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        usersRolesRequest,
        userRoleCreate,
    }),
)(Roles);
