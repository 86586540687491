import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import Structure from '../../components/structure';
import {Button} from '../../components/button';
import {NotificationsSvg} from '../../components/svg/notifications-svg';
import {AccessTimeSvg} from "../../components/svg/access-time-svg";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ReactJson from 'react-json-view'
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {getViewRequest, setNotifiesRequest, updateStatusesRequest, getTransactionRequests, getServerTimeRequest} from '../../actions/transactions';
import {Loader} from '../../components/loaders/loader';
import {format} from 'date-fns';
import Modal from '@mui/material/Modal';
import { NotifyModal } from '../../components/notify-modal';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Input} from '../../components/input';
import {EmptyDataSvg} from '../../components/svg/empty-data-svg';
import Snackbar from '@mui/material/Snackbar';
import { InfoPopup } from "../../components/info-popup";
import {useParams, useNavigate} from 'react-router-dom';
import {isJson} from '../../utils';
import TablePagination from '@mui/material/TablePagination';
import {CopyClipboard} from '../../components/clipboard-copy';
import { AccessDeniedAlert } from "../../components/access-denied";
import {NotFoundData} from '../not-found-page/data-not-found';

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const ViewHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',

    '@media(max-width: 1100px)' : {
        flexWrap: 'wrap',
        width: '100%',
    }
});

const ViewHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const ViewHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '24px',

    '@media(max-width: 1100px)' : {
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '32px',
        flexWrap: 'wrap',
    }
});

const ViewHeaderRight = styled('div')({
    display: 'flex',
    gap: '16px'

});

const IconButtonWrapper = styled('div')({
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center'
});

const ViewServerTime = styled('div')(({ theme }) => ({
    fontSize: '14px',
    marginBottom: '32px',
    color: theme.palette.text.primary,
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    '&.MuiTab-root': {
        color: '#979799'
    },
    '&.Mui-selected': {
        color: theme.palette.text.primary
    }
}));

const StyledTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: '#272F3D',
    },
});

const StyledTabPanel = styled(TabPanel)({
    padding: '0px',
    marginTop: '32px'
});

const TableTitle = styled('div')(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '20px'
}));

const ArrowContainer = styled('div')({
    cursor: 'pointer',
    marginRight: '24px',
    display: 'flex',
    alignItems: 'center'
});

const HeaderLeftContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    '@media(max-width: 540px)' : {
        width: '100%',
    }
});

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        }
    }   
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
    border-bottom: none;
    overflow: auto;
`));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: '500px',
    marginRight: '16px',
    marginTop: '20px',
    '& label.Mui-focused': {
        color: '#38AA72',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#38AA72',
        },
    },

    '@media(max-width: 768px)' : {
        width: '100%',
        marginRight: '0px',
        marginTop: '20px'
    }
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
    '&.Mui-checked': {
        color: '#38AA72'
    }
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette.text.primary,
}));

const TableIconWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
});

const TableIcon = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap'
});

const StatusInfoItem = styled('div')({
    marginBottom: '18px'
});

const StatusInfoContainer = styled('div')({
    'div': {
        '&:last-child': {
            marginBottom: '0px'
        }
    }
});

const WrapperCellRelative = styled('div')({
    position: 'relative',
});

const WrapperCoopyAbsolute = styled('div')({
    position: 'absolute',
    top: '0px',
    right: '0px',
});

const View = ({
    getViewRequest,
    setNotifiesRequest,
    updateStatusesRequest,
    viewData,
    isLoading,
    getTransactionRequests,
    transactionRequests,
    notifiesInfo,
    statuses,
    getServerTimeRequest,
    serverTime,
    timezoneCurrent,
    transactionRequestsCount,
    errorMessage,
    routsUser,
    userRoles,
    isSuperadmin,
}) => {
    const theme = useTheme();
    let ReactJsonStyle = theme.palette.mode === 'dark' ? {backgroundColor: '#272930'} : null;
    const jsonViewTheme = theme.palette.mode === 'light' ? 'bright:inverted' : 'eighties';

    const accessedRoleView = routsUser.some(rout => rout.child === '/transaction/view') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleNotify = routsUser.some(rout => rout.child === '/transaction/notify') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleUpdateStatus = routsUser.some(rout => rout.child === '/transaction/update-status') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

    const {id} = useParams();
    const navigate = useNavigate();

    const [tabValue, setTabValue] = React.useState('1');
    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        getTransactionRequests({'transactionId': id, 'page': newPage + 1});
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [notifyModalOpen, setNotifyModalOpen] = useState(false);
    const handleNotifyModalOpen = () => {
        setNotifyModalOpen(true);
    };

    const handleNotifyModalClose = () => {
        setNotifyModalOpen(false);
    };

    const [newCallback, setNewCallback] = useState('');
    const handleChangeCallback = event => {
        setNewCallback(event.target.value);
    };

    const [callbackUrl, setCallbackUrl] = useState('');
    const handleSetCallbackUrl = event => {
        setCallbackUrl(event.target.value);
    };

    const handleSetNotify = () => {
        const url = Boolean(newCallback) ? callbackUrl : null;

        const data = {
            "callbackUrl": url
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        setNotifiesRequest([{id: viewData.id, data: JSON.stringify(data)}]).then(() => handleOpenNotifiesInfoSnackbar());
        handleNotifyModalClose();
    };

    const handleUpdateStatus = () => {
        updateStatusesRequest([viewData.id]).then(() => handleOpenStatusesSnackbar());
    };

    useEffect(() => {
        if(accessedRoleView){
            getViewRequest(id);
            // getTransactionRequests({'transactionId': id, 'page': 1});
            getServerTimeRequest();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timezoneCurrent]);

    const [openNotifiesInfoSnackbar, setOpenNotifiesInfoSnackbar] = React.useState(false);

    const handleOpenNotifiesInfoSnackbar = () => {
        setOpenNotifiesInfoSnackbar(true);
    };

    const handleCloseNotifiesInfoSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenNotifiesInfoSnackbar(false);
    };

    const [openStatusesSnackbar, setOpenStatusesSnackbar] = React.useState(false);

    const handleOpenStatusesSnackbar = () => {
        setOpenStatusesSnackbar(true);
    };

    const handleCloseStatusesSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenStatusesSnackbar(false);
    };

    let notifyStatusInfo = '';
    if(viewData.notifyStatus === 0) notifyStatusInfo = 'Waiting'
    if(viewData.notifyStatus === 1) notifyStatusInfo = 'Error'
    if(viewData.notifyStatus === 2) notifyStatusInfo = 'Success'
    if(viewData.notifyStatus === 3) notifyStatusInfo = 'Final'

    return (
        <PageContainer>

            {!accessedRoleView && <AccessDeniedAlert/>}

            {accessedRoleView && 
                <>
                    {errorMessage?.response?.status !== 404 &&
                        <>
                            <ViewHeader>
                                <ViewHeaderLeft>
                                    <HeaderLeftContainer>
                                        <ArrowContainer>
                                            <ArrowBackSvg size="22px" onClick={() => navigate(-1)}/>
                                        </ArrowContainer>
                                        <ViewHeaderText
                                            data-testid="titleTranactionsView"
                                        >
                                            View
                                        </ViewHeaderText>
                                    </HeaderLeftContainer>
                                    <Structure/>
                                </ViewHeaderLeft>
                            <ViewHeaderRight>
                                {accessedRoleUpdateStatus &&
                                    <Button 
                                        onClick={handleUpdateStatus}
                                        data-testid='buttonViewUpdate'
                                    >
                                        <IconButtonWrapper>
                                        <AccessTimeSvg size="22px" />
                                        </IconButtonWrapper>
                                        UPDATE
                                    </Button>
                                }
                                {accessedRoleNotify &&
                                    <Button 
                                        variant="outlined" 
                                        onClick={handleNotifyModalOpen}
                                        data-testid='buttonViewNotify'
                                    >
                                        <IconButtonWrapper>
                                            <NotificationsSvg fill="#38AA72" size="22px" />
                                        </IconButtonWrapper>
                                        NOTIFY
                                    </Button>
                                }
                            </ViewHeaderRight>
                            </ViewHeader>

                            <ViewServerTime>
                            {`Server Time: ${serverTime}`}
                            </ViewServerTime>

                            <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={tabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <StyledTabs value={tabValue} onChange={handleChangeTab} aria-label="lab API tabs example">
                                    <StyledTab data-testid='tabViewTransactionData' label="TRANSACTION DATA" value="1" />
                                    <StyledTab data-testid='tabViewStatusHistory' label="STATUS HISTORY" value="2" />
                                    <StyledTab data-testid='tabViewRequestData' label="REQUESTS DATA" value="3"/>
                                </StyledTabs>
                            </Box>
                            <StyledTabPanel value="1">
                            <StyledPaperTable>
                            <TableContainer component={'div'}>
                            <Table>
                            <StyledTableHead>
                            <TableRow>
                                <TableCell sx={{width: '250px'}}>
                                    <div style={{width: '250px'}}>Title</div>
                                </TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                            </StyledTableHead>
                            <TableBody>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>{viewData.id}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Brand</TableCell>
                                <TableCell>{viewData.brand?.id}</TableCell>
                            </TableRow>

                            {viewData.hasOwnProperty('account') && 
                                <TableRow>
                                    <TableCell>Account Id</TableCell>
                                    <TableCell>
                                        {
                                            viewData.hasOwnProperty('account') ? viewData.account?.accountId : ''
                                        }
                                    </TableCell>
                                </TableRow>
                            }

                            <TableRow>
                                <TableCell>Payment system</TableCell>
                                <TableCell>{viewData.paymentSystem?.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Way</TableCell>
                                <TableCell>{viewData.way}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Currency</TableCell>
                                <TableCell>{viewData.currency}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Amount</TableCell>
                                <TableCell>{Number(viewData.amount)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Refund</TableCell>
                                <TableCell>
                                    {
                                        viewData.refund ? Number(viewData.refund) : ''
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Write off</TableCell>
                                <TableCell>
                                    {
                                        viewData.writeOff ? Number(viewData.writeOff) : ''
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Commission Payer</TableCell>
                                <TableCell>{viewData.commissionPayer}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Payment method</TableCell>
                                <TableCell>{viewData.paymentMethod}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Merchant transaction</TableCell>
                                <TableCell>{viewData.merchantTransactionId}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Buyer</TableCell>
                                <TableCell>{viewData.buyerId}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>External ID</TableCell>
                                <TableCell>{viewData.externalId}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Requisites</TableCell>
                                <TableCell>
                                    <WrapperCellRelative>
                                        {
                                            isJson(viewData.requisites) 
                                            ? <ReactJson
                                                src={JSON.parse(viewData.requisites)}
                                                enableClipboard={false}
                                                displayDataTypes={false}
                                                style={ReactJsonStyle}
                                                theme={jsonViewTheme}
                                            />
                                            : viewData.requisites
                                        }
                                        {viewData.requisites && 
                                            <WrapperCoopyAbsolute>
                                                <CopyClipboard data={viewData.requisites}/>
                                            </WrapperCoopyAbsolute>
                                        }
                                    </WrapperCellRelative>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Callback data</TableCell>
                                <TableCell>
                                    <WrapperCellRelative>
                                        {
                                            isJson(viewData.callbackData) 
                                            ? <ReactJson
                                                src={JSON.parse(viewData.callbackData)}
                                                enableClipboard={false}
                                                displayDataTypes={false}
                                                style={ReactJsonStyle}
                                                theme={jsonViewTheme}
                                            />
                                            : viewData.callbackData
                                        }
                                        {viewData.callbackData && 
                                            <WrapperCoopyAbsolute>
                                                <CopyClipboard data={viewData.callbackData}/>
                                            </WrapperCoopyAbsolute>
                                        }
                                    </WrapperCellRelative>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>{viewData.status}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Notify status</TableCell>
                                <TableCell>
                                    {notifyStatusInfo}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Comment</TableCell>
                                <TableCell>{viewData.comment}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Result data</TableCell>
                                <TableCell>
                                    <WrapperCellRelative>
                                        {
                                            isJson(viewData.resultData) 
                                            ? <ReactJson
                                                src={JSON.parse(viewData.resultData)}
                                                enableClipboard={false}
                                                displayDataTypes={false}
                                                style={ReactJsonStyle}
                                                theme={jsonViewTheme}
                                            />
                                            : viewData.resultData
                                        }
                                        {viewData.resultData && 
                                            <WrapperCoopyAbsolute>
                                                <CopyClipboard data={viewData.resultData}/>
                                            </WrapperCoopyAbsolute>
                                        }
                                    </WrapperCellRelative>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Query data</TableCell>
                                <TableCell>
                                    <WrapperCellRelative>
                                        {
                                            isJson(viewData.queryData) 
                                            ? <ReactJson
                                                src={JSON.parse(viewData.queryData)}
                                                enableClipboard={false}
                                                displayDataTypes={false}
                                                style={ReactJsonStyle}
                                                theme={jsonViewTheme}
                                            />
                                            : viewData.queryData
                                        }
                                        {viewData.queryData &&
                                            <WrapperCoopyAbsolute>
                                                <CopyClipboard data={viewData.queryData}/>
                                            </WrapperCoopyAbsolute>
                                        }
                                    </WrapperCellRelative>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Query url</TableCell>
                                <TableCell>
                                    <WrapperCellRelative>
                                        {viewData?.queryUrl}
                                    </WrapperCellRelative>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Urls</TableCell>
                                <TableCell>
                                    <WrapperCellRelative>
                                        {
                                            isJson(viewData.urls) ?
                                            <ReactJson
                                                src={JSON.parse(viewData.urls)}
                                                enableClipboard={false}
                                                displayDataTypes={false}
                                                style={ReactJsonStyle}
                                                theme={jsonViewTheme}
                                            />
                                            : viewData.urls
                                        }
                                        {viewData.urls && 
                                            <WrapperCoopyAbsolute>
                                                <CopyClipboard data={viewData.urls}/>
                                            </WrapperCoopyAbsolute>
                                        }
                                    </WrapperCellRelative>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Created</TableCell>
                                <TableCell>{viewData.createdAt}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Updated</TableCell>
                                <TableCell>{viewData.updatedAt}</TableCell>
                            </TableRow>
                            </TableBody>
                            </Table>
                            </TableContainer>
                            </StyledPaperTable>
                            </StyledTabPanel>
                            <StyledTabPanel value="2">
                            <StyledPaperTable>
                            <TableContainer sx={{minWidth: '600px'}} component={'div'}>
                            <Table>
                            <StyledTableHead>
                            <TableRow>
                                <TableCell sx={{width: '250px'}}>Status</TableCell>
                                <TableCell>Updated</TableCell>
                            </TableRow>
                            </StyledTableHead>
                            <TableBody>
                            {viewData?.historyTransStatuses?.length ? viewData?.historyTransStatuses?.map((row, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{row.status}</TableCell>
                                        <TableCell>{format(new Date(row.updatedAt), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                                    </TableRow>
                                )
                            }) :
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <TableIconWrapper>
                                            <TableIcon>
                                                <EmptyDataSvg height="80px"/>
                                                No results found.
                                            </TableIcon>
                                        </TableIconWrapper>
                                    </TableCell>
                                </TableRow>
                            }
                            </TableBody>
                            </Table>
                            </TableContainer>
                            </StyledPaperTable>
                            </StyledTabPanel>
                            <StyledTabPanel value="3">
                            <TableTitle>Elastic Search Requests data</TableTitle>
                            <StyledPaperTable>
                            <TableContainer component={'div'}>
                            <Table>
                            <StyledTableHead>
                            <TableRow>
                                <TableCell sx={{width: '250px'}}>
                                    <div style={{width: '250px'}}>Type</div>
                                    
                                </TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell sx={{width: '140px'}}>
                                    <div style={{width: '140px'}}>Created</div>
                                </TableCell>
                            </TableRow>
                            </StyledTableHead>
                            <TableBody>
                            {transactionRequests?.length ? transactionRequests.map((row) => {
                                return (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.type}</TableCell>
                                        <TableCell>{
                                            isJson(row.data) ?
                                            <ReactJson
                                                src={JSON.parse(row.data)}
                                                enableClipboard={false}
                                                displayDataTypes={false}
                                                style={ReactJsonStyle}
                                                theme={jsonViewTheme}
                                            />
                                            : row.data
                                        }</TableCell>
                                        <TableCell>{format(new Date(row.createdAt), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                                    </TableRow>
                                )
                            }) :
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <TableIconWrapper>
                                            <TableIcon>
                                                <EmptyDataSvg height="80px"/>
                                                No results found.
                                            </TableIcon>
                                        </TableIconWrapper>
                                    </TableCell>
                                </TableRow>
                            }
                            </TableBody>
                            </Table>
                            </TableContainer>
                            <TablePagination
                                style={{
                                    borderTop: '1px solid rgba(224, 224, 224, 1)',
                                    position: 'relative',
                                    marginTop: '-1px',
                                }}
                                rowsPerPageOptions={[20]}
                                component='div'
                                count={transactionRequestsCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                            </StyledPaperTable>
                            </StyledTabPanel>
                            </TabContext>
                            </Box>
                            <Modal 
                            open={notifyModalOpen}
                            onClose={handleNotifyModalClose}
                            >
                            <div>
                            <NotifyModal
                            title="Update status"
                            confirmButtonText='SEND NOTIFY'
                            cancelButtonText='CANCEL'
                            onClose={handleNotifyModalClose}
                            width="500px"
                            onConfirm={handleSetNotify}
                            onCancel={handleNotifyModalClose}
                            testIdConfirm='confirmUpdateStatusNotify'
                            testIdCancel='cancelUpdateStatusNotify'
                            >
                            To update status on the platform click on the “send notify” or insert a new Callback URL
                            <StyledFormControl>
                            <RadioGroup
                            name="radio-buttons-group"
                            onChange={handleChangeCallback}
                            value={newCallback}
                            >
                            <StyledFormControlLabel 
                            value="" 
                            control={
                                <StyledRadio 
                                    inputProps={{
                                        //@ts-ignore
                                        'data-testid': 'inputRadioCurrentCallback'
                                    }}
                                />
                            } 
                            label="Current Callback" 

                            />
                            <StyledFormControlLabel 
                            value="true" 
                            control={
                                <StyledRadio 
                                    inputProps={{
                                        //@ts-ignore
                                        'data-testid': 'inputRadioNewCallback'
                                    }}
                                />
                            } 
                            label="New Callback" 
                            />
                            </RadioGroup>
                            </StyledFormControl>
                            {Boolean(newCallback) && <Input sx={{marginTop: '20px'}} onChange={handleSetCallbackUrl} value={callbackUrl} placeholder="New URL"></Input>}
                            </NotifyModal>
                            </div>             
                            </Modal>
                            <Snackbar open={openNotifiesInfoSnackbar} autoHideDuration={6000} onClose={handleCloseNotifiesInfoSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                            {notifiesInfo.length ? 
                            <InfoPopup
                            severity="info"
                            headerText="Notify info"
                            dataTestid='snakebarNotifyTransactions'
                            >
                            <StatusInfoContainer>
                            {notifiesInfo.map((item) => {
                            return <StatusInfoItem key={item.id}>{`ID: ${item.id} - ${item.status}`}</StatusInfoItem>
                            })}
                            </StatusInfoContainer>
                            </InfoPopup> :
                            <InfoPopup
                            dataTestid='snakebarNotifyTransactions'
                            severity="error"
                            headerText="No data"
                            infoText={errorMessage ? errorMessage.toString() : "Something went wrong. Please try again"}
                            />}
                            </Snackbar>
                            <Snackbar open={openStatusesSnackbar} autoHideDuration={6000} onClose={handleCloseStatusesSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                            {statuses.length ?
                            <InfoPopup
                            severity="info"
                            headerText="Notify info"
                            dataTestid='snakebarStatusesTransactions'
                            >
                            <StatusInfoContainer>
                            {statuses.map((item) => {
                            return <StatusInfoItem key={item.id}>{`ID: ${item.id} - ${item.status}`}</StatusInfoItem>
                            })}
                            </StatusInfoContainer>
                            </InfoPopup> :
                            <InfoPopup
                            dataTestid='snakebarStatusesTransactions'
                            severity="error"
                            headerText="No data"
                            infoText={errorMessage ? errorMessage.toString() : "Something went wrong. Please try again"}
                            />}
                            </Snackbar>
                        </>
                    }
                    {errorMessage?.response?.status === 404 && 
                        <>
                            <ViewHeader sx={{position:'relative', zIndex: '100'}}>
                                <ViewHeaderLeft>
                                    <HeaderLeftContainer>
                                        <ArrowContainer>
                                            <ArrowBackSvg size="22px" onClick={() => navigate(-1)}/>
                                        </ArrowContainer>
                                        <ViewHeaderText
                                            data-testid="titleTranactionsView"
                                        >
                                            View
                                        </ViewHeaderText>
                                    </HeaderLeftContainer>
                                    {/* <Structure/> */}
                                </ViewHeaderLeft>
                            </ViewHeader>

                            <NotFoundData />
                        </>
                    }
                </>
            }  

            {isLoading && <Loader />}
        </PageContainer>
    );
};

export default compose(
    connect((state: RootState | any) => ({
        isLoading: state.transactions.isLoading,
        viewData: state.transactions.view,
        transactionRequests: state.transactions.transactionRequests['hydra:member'],
        transactionRequestsCount: state.transactions.transactionRequests['hydra:totalItems'],
        notifiesInfo: state.transactions.notifiesInfo,
        statuses: state.transactions.statuses,
        serverTime: state.transactions.serverTime,
        timezoneCurrent: state.currentUser.userData.timezone,
        errorMessage: state.transactions.errorMessage,
        userRoles: state.currentUser.userData.roles,
        routsUser: state.currentUser.userData.routs,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        getViewRequest, 
        setNotifiesRequest, 
        updateStatusesRequest, 
        getTransactionRequests, 
        getServerTimeRequest
    })
)(View);
